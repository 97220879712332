.main {
  width: 100%;
  height: 100%;
}

.submit {
  width: 100%;
  padding: 4rem 1rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.highlight {
  color: var(--color-primary-green);
  text-decoration: underline;
  cursor: pointer;
}

.message {
  font-size: 1.2rem;
  margin-bottom: 5rem;
}