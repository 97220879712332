.title {
  margin-bottom: 2rem;
}

.stars {
  padding: 0 .5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.star {
  cursor: pointer;
  margin: 0 10px;
}

.star i {
  font-size: 3rem;
  color: var(--color-primary-green);
}