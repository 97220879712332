.main {
  --header-height: 6px;
  --header-margin: 2rem;
  --corporate-height: 60px;
  --footer-height: 100px;
}

.header {
  height: var(--header-height);
  margin-bottom: var(--header-margin);
}

.completed {
  background-color: var(--color-primary-green);
  height: var(--header-height);
  transition: width 0.5s;
}

.container {
  overflow-x: hidden;
}

.corporate {
  width: 100%;
  display: flex;
  height: var(--corporate-height);
  align-items: center;
  justify-content: center;
}

.logo {
  max-width: calc(100% - 2rem);
  max-height: 100%;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  transition: all .5s;
}

.step {
  width: 100%;
  height: 100%;
  padding: 4rem 1rem 2rem;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.footer {
  height: var(--footer-height);
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.button {
  margin: 0 2rem;
}