.question {
  width: 100%;
  max-width: 800px;
}

.title {
  margin-bottom: 2rem;
}

.text {
  padding: 0 .5rem;
}