.title {
  margin-bottom: 3rem;
}

.faces {
  padding: 0 .5rem;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.face {
  cursor: pointer;
}

.face i {
  font-size: 5rem;
  color: var(--color-gray);
  transition: all .25s;
}

@media (max-width: 568px) {
  .face i {
    font-size: 3rem;
  }
}

@media (max-width: 360px) {
  .face i {
    font-size: 2.5rem;
  }
}

.face[data-type='very good'][data-selected="true"] i {
  color: var(--color-primary-green);
  transform: scale(1.1);
}

.face[data-type='good'][data-selected="true"] i {
  color: var(--color-primary-green-light);
  transform: scale(1.1);
}

.face[data-type='neutral'][data-selected="true"] i {
  color: var(--color-secondary-yellow);
  transform: scale(1.1);
}

.face[data-type='bad'][data-selected="true"] i {
  color: var(--color-primary-orange);
  transform: scale(1.1);
}

.face[data-type='very bad'][data-selected="true"] i {
  color: var(--color-primary-red);
  transform: scale(1.1);
}

.face[data-type='very good']:hover i {
  color: var(--color-primary-green);
  transform: scale(1.2);
}

.face[data-type='good']:hover i {
  color: var(--color-primary-green-light);
  transform: scale(1.2);
}

.face[data-type='neutral']:hover i {
  color: var(--color-secondary-yellow);
  transform: scale(1.2);
}

.face[data-type='bad']:hover i {
  color: var(--color-primary-orange);
  transform: scale(1.2);
}

.face[data-type='very bad']:hover i {
  color: var(--color-primary-red);
  transform: scale(1.2);
}